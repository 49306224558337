<template>
  <div class="thumb-example">
    <!-- swiper1 -->
    <!-- <swiper
      class="swiper gallery-top"
      :options="swiperOptionTop"
      ref="swiperTop"
    >
      <swiper-slide class="slide-1" v-for="media in images" :key="media.medium">
        <v-img :src="media.medium" :alt="'Immagine prodotto ' + name" />
      </swiper-slide>
      <div class="swiper-button-next " slot="button-next"></div>
      <div class="swiper-button-prev " slot="button-prev"></div>
    </swiper> -->
    <!-- swiper2 Thumbs -->
    <!-- <swiper
      class="swiper gallery-thumbs"
      :options="swiperOptionThumbs"
      ref="swiperThumbs"
    >
      <swiper-slide
        class="slide-thumb"
        v-for="media in images"
        :key="media.small"
      >
        <img :src="media.small" :alt="'Anteprima immagine prodotto' + name" />
      </swiper-slide>
    </swiper> -->
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide
        class="slide-custom"
        v-for="(media, index) in images"
        :key="media.medium"
      >
        <div class="d-flex flex-column justify-center align-center">
          <img
            :src="media.medium"
            :alt="'Anteprima immagine prodotto' + name"
            height="240px"
          />
          <div class="pt-3">
            {{ index + 1 }} di {{ images.length }} immagini
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>
<script>
export default {
  name: "ProductImagesSliderMobile",
  props: ["images", "name"],
  data() {
    return {
      // swiperOptionTop: {
      //   loop: true,
      //   loopedSlides: 5, // looped slides should be the same
      //   spaceBetween: 10,
      //   navigation: {
      //     nextEl: ".swiper-button-next",
      //     prevEl: ".swiper-button-prev"
      //   }
      // },
      // swiperOptionThumbs: {
      //   loop: true,
      //   loopedSlides: 5, // looped slides should be the same
      //   spaceBetween: 10,
      //   centeredSlides: false,
      //   slidesPerView: "auto",
      //   touchRatio: 0.2,
      //   slideToClickedSlide: true
      // }
      swiperOption: {
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination"
        }
      }
    };
  }
  // mounted() {
  //   this.$nextTick(() => {
  //     const swiperTop = this.$refs.swiperTop.$swiper;
  //     const swiperThumbs = this.$refs.swiperThumbs.$swiper;
  //     swiperTop.controller.control = swiperThumbs;
  //     swiperThumbs.controller.control = swiperTop;
  //   });
  // }
};
</script>
<style lang="scss" scoped>
.slide-custom {
  width: 60%;
}
.swiper {
  &::v-deep .swiper-pagination {
    bottom: 20px;
  }
  &::v-deep .swiper-pagination-bullet-active {
    background-color: var(--v-secondary-base);
  }
}
</style>
