<template>
  <div class="product-images">
    <v-row no-gutters>
      <v-col cols="12" md="12" class="d-flex justify-center">
        <div
          :style="{
            width: $vuetify.breakpoint.xs ? '100%' : '400px'
          }"
        >
          <img
            class="mt-2"
            v-if="$vuetify.breakpoint.xs"
            :src="selectedImage.medium"
            :alt="'Immagine prodotto ' + name"
            @click.stop="dialog = true"
          />
          <zoom-on-hover
            v-else
            :scale="1.5"
            :img-normal="selectedImage.medium"
            :img-zoom="selectedImage.large"
          ></zoom-on-hover>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="d-flex justify-center thumb-wrapper"
        v-if="images.length > 1"
      >
        <swiper :options="swiperOption" class="swiper" ref="swiperThumb">
          <swiper-slide v-for="media in images" :key="media.type">
            <img
              :key="'img_' + media.type"
              :src="media.small"
              :alt="'Anteprima immagine prodotto ' + media.type"
              :class="{ selected: selectedImage == media }"
              height="128px"
              @click="selectedImage = media"
            />
          </swiper-slide>
          <swiper-slide v-if="images.length === 2"></swiper-slide>
          <swiper-slide v-if="images.length <= 3"></swiper-slide>
          <div class="swiper-button-next" slot="button-next">
            <v-btn class="mr-3 white noPrint" elevation="3" x-large icon
              ><v-icon>mdi-arrow-right-thin</v-icon></v-btn
            >
          </div>
          <div class="swiper-button-prev" slot="button-prev">
            <v-btn class="ml-3 white noPrint" elevation="3" x-large icon
              ><v-icon>mdi-arrow-left-thin</v-icon></v-btn
            >
          </div>
        </swiper>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="zoom-dialog"
    >
      <div class="zoom-dialog-content">
        <v-btn @click.stop="dialog = false" dense elevation="0">Chiudi</v-btn>
        <pinch-zoom height="100%">
          <img :src="selectedImage.large" />
        </pinch-zoom>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import PinchZoom from "vue-pinch-zoom";

export default {
  name: "ProductImages",
  props: {
    images: { type: Array, required: true },
    name: { type: String, required: false },
    code: { type: String, required: false },
    highlight: { type: Boolean, required: false },
    heartKey: { type: Number, required: false }
  },
  components: { PinchZoom },
  data() {
    return {
      selectedImage: {},
      dialog: false,
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 3,
        // loop: true,
        // loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      swiperOptionThumbs: {
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 10,
        threshold: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          320: {
            slidesPerView: 3
          },
          600: {
            slidesPerView: 5
          },
          960: {
            slidesPerView: 3
          },
          1280: {
            slidesPerView: 4
          },
          1590: {
            slidesPerView: 4
          }
        }
      }
    };
  },
  computed: {
    medium() {
      return this.selectedImage && this.selectedImage.medium;
    },
    large() {
      return this.selectedImage && this.selectedImage.large;
    }
  },
  methods: {
    openZoom() {},
    toggleFavourites() {
      this.$emit("toggleFavourites");
    }
  },
  mounted() {
    this.selectedImage = this.images[0];
  }
};
</script>
<style scoped lang="scss">
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: none !important;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: none !important;
}
img.selected {
  border-bottom: 4px solid var(--v-secondary-base);
}
</style>
